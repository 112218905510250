import React from "react";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";

import CommentsCarousel from "components/FrontPage/CommentsCarousel/CommentsCarousel";
const CommentsComponentsMobile = () => {
    const theme = useTheme();
    const palette = theme.palette;
    const isNonMinMobileScreen = useMediaQuery("(min-width:500px)");

    return (
        <Box
            width={"100%"}
            height={"auto"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            marginBottom={"10vh"}
        >
            <Box
                width={isNonMinMobileScreen ? "90%" : "100%"}
                height={"auto"}
                display={"flex"}
                flexDirection={"column"}
                // justifyContent={"center"}
                alignItems={"center"}
                sx={{ boxShadow: " 0px 4px 8px rgba(0, 0, 0, 0.2)", borderRadius: "16px" }}
                paddingLeft={isNonMinMobileScreen ? "20px" : "10px"}
                paddingRight={isNonMinMobileScreen ? "20px" : "10px"}
                paddingBottom={"30px"}
            >
                <Box
                    width={"100%"}
                    height={"10vh"}
                    display={"flex"}
                    justifyContent={"center"}
                    marginBottom={"5vh"}
                    marginTop={"5vh"}
                    alignItems={"center"}
                >
                    <Typography variant="h1">
                        <span style={{ color: palette.secondary.main }}>Opinie </span>klientów
                    </Typography>
                </Box>
                <Box width={"100%"} height={"auto"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                    <CommentsCarousel />
                </Box>
            </Box>
        </Box>
    );
};
export default CommentsComponentsMobile;
