import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import logoImg from "../../assets/img/logo.png";
import FormContainer from "components/ContactComponent/FormContainer/FormContainer";

import ShadowBox from "components/Interface/ShadowBox";

const ContactComponent = () => {
    const theme = useTheme();
    const palette = theme.palette;

    return (
        <ShadowBox
            headerContent={
                <Typography variant="h1">
                    <span style={{ color: palette.secondary.main }}>Kontakt</span>
                </Typography>
            }
        >
            <Box width={"100%"} height={"75vh"} display={"flex"} flexDirection={"row"} gap={"10%"}>
                <Box width={"40%"} height="100%" paddingLeft={"10vw"}>
                    <Box width={"100%"} height={"10vh"} display={"flex"} flexDirection={"column"}>
                        <Typography fontWeight={"bold"} variant="h2" marginBottom={"3vh"}>
                            Porozmawiajmy!
                        </Typography>
                        <Typography variant="h5" marginBottom={"3vh"}>
                            Jeżeli masz jakiekolwiek pytania, potrzebujesz wyceny, lub chcesz porozmawiać o swoim
                            projekcie, jesteśmy do Twojej dyspozycji. Wypełnij poniższy formularz, a nasz zespół
                            skontaktuje się z Tobą tak szybko, jak to możliwe. Niezależnie od tego, czy masz wstępny
                            pomysł, czy już konkretną wizję, chętnie pomożemy Ci w jego realizacji. Jesteśmy tutaj, aby
                            odpowiedzieć na Twoje pytania, doradzić najlepsze rozwiązania i wspólnie znaleźć drogę do
                            sukcesu. Czekamy na Twoją wiadomość!
                        </Typography>
                        <Box display={"flex"} flexDirection={"row"} gap={"5vw"}>
                            <Typography variant="h4">
                                Do usłyszenia, zespół <spam fontWeight="bold">BYTE IT RIGHT!</spam>
                            </Typography>
                            <img src={logoImg} alt="BYTE-IT-RIGHT" className="grey-img" />
                        </Box>
                    </Box>
                </Box>
                <Box width={"30%"} height="100%">
                    <FormContainer />
                </Box>
            </Box>
        </ShadowBox>
    );
};

export default ContactComponent;
