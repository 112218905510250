import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import realizationsStepsData from "data/realizationSteps";

import Button from "components/Interface/Button";

import ShadowBox from "components/Interface/ShadowBox";

const StepsComponents = () => {
    const theme = useTheme();
    const palette = theme.palette;

    (function () {
        let isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
        let isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
        let scrollbarDiv = document.querySelector(".scrollbar");
        if (!isChrome && !isSafari) {
            scrollbarDiv.innerHTML = "You need Webkit browser to run this code";
        }
    })();

    return (
        <Box
            width={"100%"}
            height={"auto"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignContent={"center"}
            marginBottom={"20vh"}
        >
            <ShadowBox
                headerContent={
                    <Typography variant="h1">
                        Od <span style={{ color: palette.secondary.main }}>pomysłu</span> do{" "}
                        <span style={{ color: palette.secondary.main }}>realizacj</span>
                    </Typography>
                }
            >
                <Box
                    width={"90%"}
                    height={"auto"}
                    alignSelf={"center"}
                    borderRadius={"16px"}
                    display={"flex"}
                    flexDirection={"row"}
                    gap="5vw"
                    className="steps-row scrollbar"
                    padding={"30px"}
                    sx={{ overflowY: "hidden" }}
                >
                    {realizationsStepsData.map((item, index) => (
                        <Box
                            // width={"40%"}
                            borderRadius={"16px"}
                            height={"auto"}
                            // border={`1px solid ${palette.secondary.main}`}
                            // className="step-shadow"
                            sx={{
                                boxShadow: " 0px 4px 8px rgba(0, 0, 0, 0.2)",
                                borderRadius: "16px",
                            }}
                            padding={"32px"}
                            display={"flex"}
                            flex={"0 0 21vw"}
                            flexDirection={"column"}
                        >
                            <Box
                                width={"90%"}
                                height={"auto"}
                                marginBottom={"20px"}
                                display={"flex"}
                                flexDirection={"row"}
                            >
                                <Box height={"100%"} width={"auto"}>
                                    <Box
                                        borderRadius={"50%"}
                                        height={"64px"}
                                        width={"64px"}
                                        sx={{ background: palette.primary.main }}
                                        display={"flex"}
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                    >
                                        <Typography variant="h3" color={"white"} fontWeight={"bold"}>
                                            {item.id}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box
                                    height={"100%"}
                                    width={"auto"}
                                    display={"flex"}
                                    justifyItems={"center"}
                                    alignItems={"center"}
                                    // border="2px solid purple"
                                    marginLeft={"1rem"}
                                >
                                    <Typography variant="h3" fontWeight={"bold"}>
                                        {item.title}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box
                                width={"90%"}
                                height={"90%"}
                                display={"flex"}
                                flexDirection={"column"}
                                justifyContent={"center"}
                                // border={"3px solid green"}
                            >
                                <Box width={"100%"} height={"auto"} marginBottom={"20px"}>
                                    <Typography variant="h5">{item.text}</Typography>
                                </Box>
                                <Box
                                    justifySelf={"end"}
                                    width={"100%"}
                                    height={"60%"}
                                    display={"flex"}
                                    flexDirection={"column"}
                                    justifyContent={"center"}
                                    // border="2px solid red"
                                >
                                    <img
                                        src={item.image}
                                        alt="step-img"
                                        width={"95%"}
                                        height={"auto"}
                                        style={{ alignSelf: "center", justifySelf: "center" }}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    ))}
                </Box>
                <Box
                    width={"90%"}
                    height={"10vh"}
                    display={"flex"}
                    alignSelf={"center"}
                    justifyContent={"end"}
                    alignItems={"center"}
                >
                    <Button
                        text={"Bezpłatna wycena"}
                        url={"/calculator"}
                        width={"206px"}
                        height={"48px"}
                        variant={"h5"}
                    />
                </Box>
            </ShadowBox>
        </Box>
    );
};

export default StepsComponents;
