import React from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import logoImg from "../../assets/img/logo.png";
import { FaBars } from "react-icons/fa";
import headerData from "data/header";
import { IoIosClose } from "react-icons/io";

const HeaderMobile = () => {
    const theme = useTheme();
    const isNonMinMobileScreen = useMediaQuery("(min-width:500px)");

    const navigate = useNavigate();
    const [isHovered, setIsHovered] = useState(false);

    const [isShown, setIsShown] = useState(false);

    return (
        <Box height={isNonMinMobileScreen ? "auto" : "10vh"} width={"100%"} display={"flex"} flexDirection={"column"}>
            <Box display={"flex"} flexDirection={"row"} width={"100%"} justifyContent={"space-between"} height={"100%"}>
                <Box
                    sx={{
                        marginLeft: "5vw",
                        cursor: "pointer",
                        fontSize: "2rem",
                        "&:hover": {
                            color: theme.palette.secondary.main,
                        },
                        display: "inline-flex",
                        alignItems: "center",
                    }}
                    onClick={(e) => {
                        setIsShown((item) => !item);
                    }}
                >
                    {isShown ? <IoIosClose size={"3rem"} /> : <FaBars />}
                </Box>
                <Box
                    alignSelf={"center"}
                    marginRight={"5vw"}
                    height={"100%"}
                    sx={{
                        cursor: "pointer",
                    }}
                    onClick={(e) => {
                        if (isShown) {
                            setIsShown((item) => !item);
                        }
                        navigate("/", { state: { scrollToStart: true } });
                    }}
                >
                    <img src={logoImg} alt="logo" height={"100%"} width={"auto"} className="grey-img" />
                </Box>
            </Box>
            {isShown ? (
                <Box
                    width={"100%"}
                    height={"auto"}
                    zIndex={"100"}
                    display={"flex"}
                    flexDirection={"column"}
                    sx={{ background: "white" }}
                >
                    {headerData.map((item, index) => (
                        <Box
                            sx={{
                                color: "#1D3557",
                                textAlign: "center",
                                // background: "#1D3557",
                                background: "white",
                                height: "6vh",
                                cursor: "pointer",
                                "&:hover": {
                                    // background: `#0C6CE9`,
                                    color: theme.palette.secondary.main,
                                    borderRadius: "16px",
                                },
                            }}
                            display={"flex"}
                            alignItems={"center"}
                            marginLeft={"5vw"}
                            marginRight={"5vw"}
                            paddingLeft={"2vw"}
                            onMouseEnter={() => setIsHovered(index)}
                            onMouseLeave={() => setIsHovered(null)}
                        >
                            <Typography
                                variant="h3"
                                onClick={() => {
                                    setIsShown((item) => !item);
                                    navigate(item.url, { state: item.state });
                                }}
                            >
                                {item.title}
                            </Typography>
                        </Box>
                    ))}
                    <Box
                        sx={{
                            color: "white",
                            background: "#1D3557",
                            textAlign: "center",
                            height: "6vh",
                            cursor: "pointer",
                            borderRadius: "16px",
                            "&:hover": {
                                background: `#0C6CE9`,
                                // color: theme.palette.secondary.main,
                            },
                        }}
                        display={"flex"}
                        alignItems={"center"}
                        justifyItems={"center"}
                        justifyContent={"center"}
                        marginLeft={"5vw"}
                        marginRight={"5vw"}
                        paddingLeft={"2vw"}
                        marginTop={"20px"}
                        marginBottom={"20px"}
                    >
                        <Typography
                            variant={isNonMinMobileScreen ? "h3" : "h4"}
                            onClick={() => {
                                setIsShown((item) => !item);
                                navigate("/calculator", { state: { scrollToStart: true } });
                            }}
                        >
                            Bezpłatna Wycena
                        </Typography>
                    </Box>
                </Box>
            ) : (
                <Box></Box>
            )}
        </Box>
    );
};

export default HeaderMobile;
