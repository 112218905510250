
import React from "react";
import { Box, Typography, useTheme } from "@mui/material";

const ServicesPage = ({ details }) => {
    const theme = useTheme();

    return (
        <Box display="flex" flexDirection={{ xs: "column", md: "row" }} alignItems="flex-start">
            <Box flexBasis="50%" flexShrink={0} mr={{ xs: 0, md: 4 }} mb={{ xs: 4, md: 0 }}>
                <img
                    src={details.imageUrl}
                    alt={details.title}
                    style={{ width: "100%", height: "auto", borderRadius: theme.shape.borderRadius }}
                />
            </Box>

            {/* Right side: Text content */}
            <Box flexBasis="60%">
                <Typography variant="h1" gutterBottom>
                    {details.title}
                </Typography>
                <Typography variant="h4" gutterBottom>
                    {details.description}
                </Typography>

                {/* Iterate over the subtitles array to render each subtitle and its contents */}
                {details.subtitles.map((subtitle, index) => (
                    <Box key={index} mt={4}>
                        <Typography variant="h5" gutterBottom>
                            {subtitle.title}
                        </Typography>
                        {subtitle.contents.map((content, idx) => (
                            <Typography key={idx} variant="body1" paragraph>
                                {content}
                            </Typography>
                        ))}
                    </Box>
                ))}

                <Typography variant="h6" mt={4}>
                    {details.footer}
                </Typography>
            </Box>
        </Box>
    );
};

export default ServicesPage;

