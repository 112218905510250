import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";

import FindBet from "components/PortfolioComponents/ComponentSlides/FindBet/FindBet";
import FindBetMobile from "components/PortfolioComponents/ComponentSlides/FindBet/FindBetMobile";

import Connect from "components/PortfolioComponents/ComponentSlides/Connect/Connect";
import ConnectMobile from "components/PortfolioComponents/ComponentSlides/Connect/ConnectMobile";

const PortfolioPage = () => {
    const theme = useTheme();
    const location = useLocation();
    const palette = theme.palette;
    const isNonMinMobileScreen = useMediaQuery("(min-width:1000px)");

    useEffect(() => {
        if (location.state?.scrollToStart) {
            const element = document.getElementById("start");
            if (element) {
                element.scrollIntoView();
            }
        }
    }, [location.state]);

    return (
        <Box
            width={"100vw"}
            height={"auto"}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
        >
            <div id="start" />
            <Box
                width={"100%"}
                height={"10vh"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                marginTop={"10vh"}
                marginBottom={"10vh"}
            >
                <Typography variant="h1">
                    <span style={{ color: palette.secondary.main }}>Portfolio</span>
                </Typography>
            </Box>
            <Box
                width={isNonMinMobileScreen ? "80vw" : "95vw"}
                height={"auto"}
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={"120px"}
                marginBottom={"10vh"}
            >
                {isNonMinMobileScreen ? <FindBet /> : <FindBetMobile />}

                {isNonMinMobileScreen ? <Connect /> : <ConnectMobile />}
            </Box>
        </Box>
    );
};

export default PortfolioPage;
