import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { Box, useMediaQuery } from "@mui/material";
import CalculatorComponent from "components/CalculatorComponent/CalculatorComponent";

import ContactComponent from "components/ContactComponent/ContactComponent";
import ContactComponentMobile from "components/ContactComponent/ContactComponentMobile";

const CalculatorPage = () => {
    const location = useLocation();
    const isNonMobileScreen = useMediaQuery("(min-width:1000px)");

    useEffect(() => {
        // Sprawdzenie, czy stan został przekazany
        if (location.state?.scrollToStart) {
            const element = document.getElementById("start");
            if (element) {
                element.scrollIntoView();
            }
        }
    }, [location.state]);

    return (
        <Box height={"auto"} minHeight={"100vh"} display={"flex"} flexDirection={"column"}>
            <div id="start" />
            <CalculatorComponent />
            {isNonMobileScreen ? <ContactComponent /> : <ContactComponentMobile />}
        </Box>
    );
};

export default CalculatorPage;
