import profile from "../assets/img/profile.png";
import stars from "../assets/img/stars.png";
const comments = [
    {
        stars: stars,
        image: profile,
        name: "Konrad Turyk",
        company: "Nestle",
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer.",
    },
    {
        stars: stars,
        image: profile,
        name: "Inny Klient",
        company: "Firma",
        text: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s...",
    },
    {
        stars: stars,
        image: profile,
        name: "Anna Kowalska",
        company: "Coca-Cola",
        text: "Lorem Ipsum has been the industry's standard dummy text ever since the 1600s...",
    },
    {
        image: profile,
        name: "Michał Nowak",
        company: "PepsiCo",
        text: "Lorem Ipsum is simply dummy text of the printing industry. It has survived not only five centuries...",
    },
    {
        name: "Ewa Wiśniewska",
        company: "Unilever",
        text: "Lorem Ipsum is not just random text. It has roots in a piece of classical Latin literature from 45 BC...",
    },
    {
        name: "Jan Kowalski",
        company: "Procter & Gamble",
        text: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin...",
    },
];

export default comments;
