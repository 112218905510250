import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import findBetImg from "../../../../assets/img/findbet.png";

const FindBet = () => {
    const theme = useTheme();
    const palette = theme.palette;

    const handleClick = () => {
        window.location.href = "https://findbet.pl";
    };

    return (
        <Box
            width={"100%"}
            display={"flex"}
            flex={"row"}
            // border={"2px solid red"}
            borderRadius={"16px"}
            padding={"40px"}
            onClick={handleClick}
            sx={{
                cursor: "pointer",
                border: `2px solid white`,
                boxShadow: " 0px 4px 8px rgba(0, 0, 0, 0.2)",
                borderRadius: "16px",
                "&:hover": {
                    border: `2px solid ${palette.secondary.main}`,
                },
            }}
        >
            <Box
                width={"25%"}
                height={"100%"}
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                // border={"2px solid red"}
            >
                <img src={findBetImg} className="opacity-img" alt="FindBet" height={"330px"} width={"auto"} />
            </Box>
            <Box width={"75%"} height={"100%"} display={"flex"} flexDirection={"column"} gap={"20px"}>
                <Box>
                    <Typography variant="h1">
                        Find<span style={{ color: palette.secondary.main }}>BET</span>
                    </Typography>
                </Box>
                <Box>
                    <Typography variant="p">
                        FindBET to innowacyjna platforma mobilna zrzeszająca najlepszych typerów bukmacherskich.
                        Umożliwia użytkownikom sprzedaż i zakup analiz bukmacherskich, zapewniając tym samym dostęp do
                        ekspertyz i prognoz od najlepszych w branży. Naszym celem było stworzenie intuicyjnego i
                        bezpiecznego narzędzia, które łączy społeczność typerów, dając im przestrzeń do dzielenia się
                        wiedzą i zwiększania swoich zysków.
                    </Typography>
                </Box>
                <Box>
                    <Typography variant="p">
                        Projekt ten jest owocem intensywnej pracy zespołu, który zadbał o zaawansowane integracje z
                        zewnętrznymi systemami. Technologie użyte w projekcie:
                    </Typography>
                    <ul class="custom-dot-list">
                        <li>
                            <Typography variant="p">
                                Stripe: Bezpieczne i szybkie przetwarzanie płatności, umożliwiające użytkownikom
                                bezproblemowe transakcje.
                            </Typography>
                        </li>
                        <li>
                            {" "}
                            <Typography variant="p">
                                Flutter: Crossplatformowa aplikacja mobilna na systemy iOS oraz Android.{" "}
                            </Typography>
                        </li>
                        <li>
                            {" "}
                            <Typography variant="p">
                                Firebase: Wsparcie dla powiadomień push, analityki oraz autoryzacji użytkowników, co
                                zwiększa funkcjonalność i niezawodność aplikacji.{" "}
                            </Typography>
                        </li>
                        <li>
                            {" "}
                            <Typography variant="p">
                                Spring Boot: Napisaliśmy customowe web API oparte na Spring Boot, wybierając tę
                                technologię ze względu na jej popularność, elastyczność i doskonałą wydajność.{" "}
                            </Typography>
                        </li>
                    </ul>
                </Box>
            </Box>
        </Box>
    );
};

export default FindBet;
