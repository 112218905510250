import { useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import FrontPage from "pages/FrontPage";
import ServicesPage from "pages/ServicesPage";
import { themeSettings } from "theme";
import { createTheme } from "@mui/material";
import servicesData from "data/services";
import { ThemeProvider } from "@mui/material";
import Header from "components/Header/Header";
import Footer from "components/Footer/FooterComponent";
import PortfolioPage from "pages/PortfolioPage";
import CalculatorPage from "pages/CalculatorPage";
const theme = createTheme(themeSettings("light"));

const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
};

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <ThemeProvider theme={theme}>
                    <Header />
                    <ScrollToTop />
                    <Routes>
                        <Route path="/" element={<FrontPage />}></Route>
                        <Route path="/portfolio" element={<PortfolioPage />}></Route>
                        <Route path="/calculator" element={<CalculatorPage />}></Route>
                        {servicesData.map((e) => {
                            return (
                                <Route key={e.url} path={e.url} element={<ServicesPage details={e.details} />}></Route>
                            );
                        })}
                    </Routes>
                    <Footer />
                </ThemeProvider>
            </BrowserRouter>
        </div>
    );
}

export default App;
