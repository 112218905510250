import React from "react";
import { useState, useEffect, useRef } from "react";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";

import calculatorData from "data/calculatorData";
import "./CalculatorStyles.scss";

const CalculatorCard = ({
    text,
    price,
    image,
    selectOption,
    handleSelect,
    handleCalculate,
    setSectionsToShow,
    clearValue,
    rowNumber,
}) => {
    const theme = useTheme();
    const palette = theme.palette;

    const isNonMinMobileScreen = useMediaQuery("(min-width:500px)");

    const [picked, setPicked] = useState(null);

    useEffect(() => {
        if (handleCalculate === undefined) {
            return;
        }
        if (picked === true) {
            handleCalculate((prev) => prev + price);
        } else if (picked === false) {
            handleCalculate((prev) => prev - price);
        }
    }, [picked]);

    useEffect(() => {
        setPicked(null);
    }, [clearValue]);

    const handlePicked = () => {
        setPicked((prev) => (prev === null ? true : !prev));
        handleSelect(selectOption);
        setSectionsToShow(rowNumber + 1);
    };

    return (
        <Box
            width={isNonMinMobileScreen ? "250px" : "100px"}
            height={isNonMinMobileScreen ? "250px" : "100px"}
            sx={{
                border: picked ? `4px solid ${palette.secondary.main}` : `2px solid white`,
                boxShadow: " 0px 4px 8px rgba(0, 0, 0, 0.2)",
                borderRadius: "16px",
                cursor: "pointer",
                "&:hover": {
                    border: `2px solid ${palette.secondary.main}`,
                },
                "&:active": {
                    transform: "translateY(4px)",
                },
            }}
            display={"flex"}
            flexDirection={"column"}
            alignContent={"center"}
            alignItems={"center"}
            justifyContent={"center"}
            onClick={() => {
                handlePicked();
            }}
        >
            <Typography
                variant={isNonMinMobileScreen ? "h3" : "p"}
                marginBottom={isNonMinMobileScreen ? "20px" : "5px"}
                textAlign={"center"}
            >
                {text}
            </Typography>
            <img
                src={image}
                alt="text"
                height={isNonMinMobileScreen ? "130px" : "50px"}
                width={isNonMinMobileScreen ? "130px" : "50px"}
            />
        </Box>
    );
};

const CalculatorRow = ({
    data,
    handleSelect,
    setSectionsToShow,
    handleCalculate,
    clearValue,
    setShowButton,
    rowNumber,
}) => {
    const isNonMinMobileScreen = useMediaQuery("(min-width:500px)");

    return (
        <Box
            width={"100%"}
            height={"auto"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignContent={"center"}
            padding={isNonMinMobileScreen ? "30px" : "0px"}
            marginBottom={"5vh"}
        >
            <Box width={"100%"} marginBottom={"3vh"} textAlign={"center"}>
                <Typography variant={isNonMinMobileScreen ? "h3" : "h4"}>{data.question}</Typography>
            </Box>
            <Box
                width={"100%"}
                display={"flex"}
                flexWrap={"wrap"}
                gap={"20px"}
                justifyContent={"center"}
                justifyItems={"center"}
                alignContent={"center"}
            >
                {data.options.map((item, index) => (
                    <CalculatorCard
                        handleSelect={handleSelect ? handleSelect : () => {}}
                        setSectionsToShow={setSectionsToShow ? setSectionsToShow : () => {}}
                        selectOption={item.selectOption}
                        price={item.price}
                        text={item.title}
                        image={item.icon}
                        rowNumber={rowNumber}
                        clearValue={clearValue}
                        handleCalculate={handleCalculate}
                    />
                ))}
            </Box>
        </Box>
    );
};

const SelectedComponent = ({ isChose, setShowButton, handleCalculate, clearValue }) => {
    const [showComponent, setShowComponent] = useState(false);
    const [sectionsToShow, setSectionsToShow] = useState(0);

    useEffect(() => {
        if (isChose) {
            setShowComponent(true); // Ustaw na true, gdy isChose się zmieni
        }
    }, [isChose]);

    useEffect(() => {
        setShowComponent(false);
        setSectionsToShow(0);
    }, [clearValue]);

    const dataToDisplay = isChose === "web" ? calculatorData.web : calculatorData.mobile;

    useEffect(() => {
        if (dataToDisplay.length === sectionsToShow) {
            setShowButton(true);
        }
    }, [sectionsToShow]);

    if (!showComponent) return null; // Nie renderuj komponentu, dopóki nie jest wybrany

    return (
        <Box>
            {dataToDisplay.slice(0, sectionsToShow + 1).map((item, index) => (
                <Box key={index} className="fade-in">
                    <CalculatorRow
                        data={item}
                        setSectionsToShow={setSectionsToShow}
                        handleCalculate={handleCalculate}
                        clearValue={clearValue}
                        rowNumber={index}
                        marginBottom="10vh"
                    />
                </Box>
            ))}
        </Box>
    );
};

const CalculatorComponent = () => {
    const theme = useTheme();
    const palette = theme.palette;
    const isNonMinMobileScreen = useMediaQuery("(min-width:500px)");

    const [isChose, setIsChose] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const [calculate, setCalculate] = useState(false);

    const [calculatePrice, setCalculatePrice] = useState(0);
    const [clear, setClear] = useState(false);

    const sectionRef = useRef(null);

    const handleClear = () => {
        sectionRef.current?.scrollIntoView({ behavior: "smooth" });
        setIsChose(false);
        setShowButton(false);
        setCalculate(false);
        setCalculatePrice(0);
        setClear((prev) => !prev);
    };

    return (
        <Box
            width={"100%"}
            height={"auto"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignContent={"center"}
        >
            <Box
                width={isNonMinMobileScreen ? "90vw" : "100%"}
                minHeight={"60vh"}
                marginLeft={"auto"}
                marginRight={"auto"}
                marginTop={"10vh"}
                marginBottom={"10vh"}
                display={"flex"}
                flexDirection={"column"}
                padding={isNonMinMobileScreen ? "30px" : "5px"}
                ref={sectionRef}
                sx={{ boxShadow: " 0px 4px 8px rgba(0, 0, 0, 0.2)", borderRadius: "16px" }}
            >
                <Typography
                    variant="h1"
                    marginTop={"5vh"}
                    marginBottom={"5vh"}
                    marginLeft={"auto"}
                    marginRight={"auto"}
                >
                    <span style={{ color: palette.secondary.main }}>Kalkulator</span>
                </Typography>
                <CalculatorRow
                    data={calculatorData.first[0]}
                    handleSelect={setIsChose}
                    clearValue={clear}
                    marginBottom="10vh"
                />
                <SelectedComponent
                    clearValue={clear}
                    isChose={isChose}
                    handleCalculate={setCalculatePrice}
                    setShowButton={setShowButton}
                />

                {showButton ? (
                    <Box
                        width={isNonMinMobileScreen ? "90%" : "100%"}
                        height={"10vh"}
                        display={"flex"}
                        alignSelf={"center"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        marginBottom={"10vh"}
                        className="fade-in"
                        padding={"10px"}
                    >
                        <Box
                            borderRadius={"16px"}
                            sx={{
                                background: "#1D3557",
                                boxShadow: " 0px 4px 8px rgba(0, 0, 0, 0.2)",
                                cursor: "pointer",
                                "&:hover": {
                                    background: `#0C6CE9`,
                                },
                            }}
                            width={isNonMinMobileScreen ? "206px" : "150px"}
                            height={"46px"}
                            textAlign={"center"}
                            display={"flex"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            onClick={handleClear}
                        >
                            <Typography
                                variant={isNonMinMobileScreen ? "h5" : "p"}
                                textAlign={"left"}
                                fontWeight={"bold"}
                                borderRadius={"16px"}
                                color={"white"}
                            >
                                Zresetuj obliczenia
                            </Typography>
                        </Box>

                        <Box
                            borderRadius={"16px"}
                            sx={{
                                background: "#1D3557",
                                boxShadow: " 0px 4px 8px rgba(0, 0, 0, 0.2)",
                                cursor: "pointer",
                                "&:hover": {
                                    background: `#0C6CE9`,
                                },
                            }}
                            width={isNonMinMobileScreen ? "206px" : "80px"}
                            height={"46px"}
                            textAlign={"center"}
                            display={"flex"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            onClick={() => setCalculate(true)}
                        >
                            <Typography
                                variant={isNonMinMobileScreen ? "h5" : "p"}
                                textAlign={"left"}
                                fontWeight={"bold"}
                                borderRadius={"16px"}
                                color={"white"}
                            >
                                Oblicz
                            </Typography>
                        </Box>
                    </Box>
                ) : (
                    <></>
                )}

                {calculate ? (
                    <Box
                        width={"90%"}
                        display={"flex"}
                        flexDirection={"column"}
                        alignContent={"center"}
                        alignItems={"center"}
                        marginBottom={"5vh"}
                        className="fade-in"
                        marginLeft={"auto"}
                        marginRight={"auto"}
                    >
                        <Box width={isNonMinMobileScreen ? "650px" : "100%"} display={"flex"} flexDirection={"column"}>
                            <Typography variant="h4" fontWeight={"bold"} marginBottom={"20px"}>
                                Możemy zrealizować twój pomysł JUŻ OD{" "}
                                <spna style={{ textDecoration: "line-through" }}>
                                    {calculatePrice + calculatePrice / 2} PLN.
                                </spna>
                                <spna
                                    marginTop="-10px"
                                    className={isNonMinMobileScreen ? "correct-price" : "correct-price-min"}
                                >
                                    {calculatePrice} PLN!
                                </spna>
                            </Typography>
                            <Typography variant="h5" fontWeight={"bold"} textAlign={"start"}>
                                Chcielibyśmy jednak zaznaczyć, że wyliczona kwota jest jedynie szacunkowa i może się
                                różnić od ostatecznej wyceny. Dokładna cena zależy od wielu czynników, takich jak zakres
                                prac, złożoność projektu czy dodatkowe funkcjonalności. Dlatego po wstępnej wycenie
                                zachęcamy do kontaktu z naszym zespołem, który przygotuje dla Ciebie szczegółową ofertę,
                                dostosowaną do Twoich specyficznych wymagań. Dziękujemy za zaufanie i czekamy na
                                możliwość współpracy!
                            </Typography>
                        </Box>
                    </Box>
                ) : (
                    <></>
                )}
            </Box>
        </Box>
    );
};

export default CalculatorComponent;
