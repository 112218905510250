import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";

import LogoWaterfall from "components/FrontPage/LogoWaterfall";
import LogoWaterfallMobile from "../components/FrontPage/LogoWaterfallMobile";

import ServicesComponent from "components/FrontPage/ServicesComponent/ServicesComponent";
import ServicesComponentMobile from "components/FrontPage/ServicesComponent/ServicesComponentMobile";

import CommentsComponent from "components/FrontPage/CommentsComponent/CommentsComponent";
import CommentsComponentsMobile from "components/FrontPage/CommentsComponent/CommentsComponentsMobile";

import StepsComponents from "components/FrontPage/StepsComponents/StepsComponents";
import StepsComponentMobile from "components/FrontPage/StepsComponents/StepsComponentsMobile";

import ContactComponent from "components/ContactComponent/ContactComponent";
import ContactComponentMobile from "components/ContactComponent/ContactComponentMobile";

const FrontPage = () => {
    const theme = useTheme();
    const location = useLocation();

    const palette = theme.palette;
    const isNonMobileScreen = useMediaQuery("(min-width:1000px)");

    useEffect(() => {
        // Sprawdzenie, czy stan został przekazany
        if (location.state?.scrollToForm) {
            const element = document.getElementById("formularz");
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            }
        } else if (location.state?.scrollToStart) {
            const element = document.getElementById("start");
            if (element) {
                element.scrollIntoView();
            }
        }
    }, [location.state]);

    return (
        <Box>
            <div id="start" />
            {isNonMobileScreen ? (
                <Box width={"100%"} height={"100vh"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                    <Box width={"40%"}>
                        <Box
                            width={"70%"}
                            display={"flex"}
                            flexDirection={"column"}
                            marginLeft={"auto"}
                            marginRight={"auto"}
                            gap={"25px"}
                        >
                            <Typography variant="h1">
                                Tworzymy dedytkowane <span style={{ color: palette.secondary.main }}>aplikacje</span>{" "}
                                dla firm.
                            </Typography>
                            <Typography variant="h3">
                                Tworzymy oprogramowanie, które spełnia najwyższe oczekiwania biznesu, dopasowane do
                                Twoich potrzeb. Pracujemy z najnowszymi technologiami, aby dostarczać rozwiązania, które
                                napędzają Twój sukces.
                            </Typography>
                        </Box>
                    </Box>
                    <Box width={"50%"} height={"80vh"}>
                        <LogoWaterfall />
                    </Box>
                </Box>
            ) : (
                <LogoWaterfallMobile />
            )}

            {/* RESPONSYWNOŚĆ: <KLASYCZNY KOMONENT > 1000 PX | 1000 > MOBILE KOMPONENT > 400 | 400 > MIN MOBILE KOMPONENT  */}

            {isNonMobileScreen ? <ServicesComponent /> : <ServicesComponentMobile />}

            {isNonMobileScreen ? <CommentsComponent /> : <CommentsComponentsMobile />}

            {isNonMobileScreen ? <StepsComponents /> : <StepsComponentMobile />}

            {isNonMobileScreen ? <ContactComponent /> : <ContactComponentMobile />}
        </Box>
    );
};

export default FrontPage;
