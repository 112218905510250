import React from "react";
import { Box, useMediaQuery } from "@mui/material";

const ShadowBox = ({ headerContent, children }) => {
    const isNonMinMobileScreen = useMediaQuery("(min-width:500px)");
    return (
        <Box
            width={isNonMinMobileScreen ? "90%" : "100%"}
            height={"auto"}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            sx={{ boxShadow: " 0px 4px 8px rgba(0, 0, 0, 0.2)", borderRadius: "16px" }}
            paddingLeft={isNonMinMobileScreen ? "20px" : "0px"}
            paddingRight={isNonMinMobileScreen ? "20px" : "0px"}
            paddingBottom={"30px"}
            marginLeft={"auto"}
            marginRight={"auto"}
        >
            <Box
                width={"100%"}
                height={"10vh"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                marginLeft={"auto"}
                marginRight={"auto"}
                marginBottom={isNonMinMobileScreen ? "3vh" : "14px"}
                marginTop={isNonMinMobileScreen ? "3vh" : "14px"}
            >
                {headerContent}
            </Box>

            {children}
        </Box>
    );
};

export default ShadowBox;
