import apiImg from "../assets/services/api.svg";
import designImg from "../assets/services/design.svg";
import webImg from "../assets/services/web.svg";
import phoneImg from "../assets/services/phone.svg";
import rocketImg from "../assets/services/rocket.svg";
import paperClipImg from "../assets/services/paper-clip.svg";

import servicesDetails from "./servicesDetails";

const servicesData = [
    {
        title: "Aplikacje webowe",
        text: "Tworzymy nowoczesne, intuicyjne i funkcjonalne aplikacje, które pozwalają Twojemu biznesowi być zawsze blisko użytkowników. Niezależnie od tego, czy potrzebujesz aplikacji do sprzedaży, budowania lojalności, czy optymalizacji procesów, dostarczymy rozwiązania idealnie dopasowane do Twoich potrzeb.",
        image: webImg,
        url: "/services/webapp",
        details: servicesDetails.webapp,
        colorVariant: 1,
    },
    {
        title: "Aplikacje mobilne",
        text: "Projektujemy i rozwijamy aplikacje mobilne, które nie tylko promują Twoją markę, ale również umożliwiają bezpośrednią interakcję z klientami. Nasze aplikacje są intuicyjne, szybkie i dostosowane do potrzeb Twojego biznesu, niezależnie od branży.",
        image: phoneImg,
        url: "/services/mobileapp",
    },
    {
        title: "Web API",
        text: "Tworzymy niezawodne i skalowalne API, które pozwala na płynne przekazywanie danych i integrację różnych platform. Nasze Web API jest projektowane z myślą o wysokiej wydajności, bezpieczeństwie i łatwości w zarządzaniu, dzięki czemu Twój system działa sprawnie i efektywnie.",
        image: apiImg,
        url: "/services/webapi",
    },
    {
        title: "Projektowanie UI/UX",
        text: "Dobrze zaprojektowane UI/UX to klucz do sukcesu każdej aplikacji czy strony internetowej. Nasz zespół specjalistów tworzy interfejsy, które są nie tylko estetyczne, ale przede wszystkim intuicyjne i przyjazne dla użytkownika.",
        image: designImg,
        url: "/services/uiux",
    },
    {
        title: "Integracje z systemami",
        text: "Tworzymy rozwiązania, które umożliwiają automatyzację procesów, poprawę przepływu informacji i eliminację ręcznej pracy. Bez względu na to, czy potrzebujesz połączenia z systemami ERP, CRM, czy platformami e-commerce, dostarczymy niezawodne integracje, które zapewnią Twojej firmie większą efektywność i kontrolę nad operacjami.",
        image: paperClipImg,
        url: "/services/integrations",
    },
    {
        title: "MVP",
        text: "Minimal Viable Product (MVP) to idealne rozwiązanie, aby szybko przetestować i wprowadzić na rynek swój pomysł. Tworzymy funkcjonalne wersje produktów, które pozwalają na szybkie zebranie opinii użytkowników i weryfikację założeń biznesowych przy minimalnym nakładzie czasu i zasobów.",
        image: rocketImg,
        url: "/services/mvp",
    },
];

export default servicesData;
