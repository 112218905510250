import React from "react";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import ServicesMobile from "./ServicesMobile";

import servicesData from "../../../data/services";

import ShadowBox from "components/Interface/ShadowBox";

const ServicesComponentMobile = () => {
    const theme = useTheme();
    const palette = theme.palette;
    const isNonMinMobileScreen = useMediaQuery("(min-width:500px)");

    return (
        <Box
            width={"100%"}
            height={"auto"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            marginBottom={"20vh"}
        >
            <ShadowBox
                headerContent={
                    <Typography variant="h1">
                        Nasze <span style={{ color: palette.secondary.main }}>usługi</span>
                    </Typography>
                }
            >
                <Box
                    width={"100%"}
                    height="auto"
                    display={"flex"}
                    flexDirection={"column"}
                    gap={"5vh"}
                    paddingLeft={isNonMinMobileScreen ? "50px" : "10px"}
                    paddingRight={isNonMinMobileScreen ? "50px" : "10px"}
                    // border={"2px solid red"}
                >
                    {servicesData.map((item) => (
                        <ServicesMobile item={item} />
                    ))}
                </Box>
            </ShadowBox>
        </Box>
    );
};

export default ServicesComponentMobile;
