import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import connectLogoImg from "../../../../assets/img/connect-logo.svg";
import connectImg from "../../../../assets/img/pc_connect.png";
const Connect = () => {
    const theme = useTheme();
    const palette = theme.palette;

    return (
        <Box
            width={"100%"}
            height={"100%"}
            display={"flex"}
            flex={"row"}
            border={"2px solid red"}
            borderRadius={"16px"}
            padding={"40px"}
            sx={{
                border: `2px solid white`,
                boxShadow: " 0px 4px 8px rgba(0, 0, 0, 0.2)",
                borderRadius: "16px",
                "&:hover": {
                    border: `2px solid ${palette.secondary.main}`,
                },
            }}
        >
            <Box
                width={"25%"}
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                justifyContent={"center"}
            >
                <img src={connectImg} className="opacity-img" alt="connectImg" height={"auto"} width={"100%"} />
            </Box>
            <Box width={"60%"} height={"100%"} display={"flex"} flexDirection={"column"} gap={"20px"}>
                <Box>
                    <img src={connectLogoImg} width={"136px"} height={"auto"} alt="connect-logo" />
                </Box>
                <Box>
                    <Typography variant="p">
                        Connect to nowoczesna platforma, zaprojektowana z myślą o studentach w Polsce. Nasza platforma
                        łączy studentów na tej samej uczelni, kierunku i roku, tworząc sieć kontaktów, która ułatwia
                        codzienne życie akademickie. Dzięki Connect studenci mogą łatwo wymieniać się informacjami,
                        zarządzać wydarzeniami uczelnianymi oraz śledzić ważne terminy, takie jak kolokwia i inne testy.
                        Projekt składa się z aplikacji mobilnej, striny internetowej oraz web api.
                    </Typography>
                </Box>
                <Box>
                    <Typography variant="p">Użyte technologie:</Typography>
                    <ul class="custom-dot-list">
                        <li>
                            <Typography variant="p">React: Responsywna i szybka aplikacja webowa</Typography>
                        </li>
                        <li>
                            <Typography variant="p">
                                Flutter: Crossplatformowa aplikacja mobilna na systemy iOS oraz Android
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="p">.Net: Nowoczesne web api</Typography>
                        </li>
                    </ul>
                </Box>
            </Box>
        </Box>
    );
};

export default Connect;
