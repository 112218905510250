const servicesDetails = {
    webapp: {
        imageUrl:'',
        title: 'Aplikacje webowe',
        description: 'Aplikacje webowe to programy dostępne bezpośrednio przez przeglądarkę internetową, oferujące pełną funkcjonalność bez konieczności instalowania dodatkowego oprogramowania na komputerze czy urządzeniu mobilnym. Dzięki swojej elastyczności i dostępności, aplikacje webowe są idealnym rozwiązaniem dla firm, które pragną zapewnić szybki i łatwy dostęp do swoich usług i produktów.',
        footer:'Nasze aplikacje webowe to nowoczesne narzędzia, które wspierają Twój biznes, ułatwiając zarządzanie procesami i dostarczanie usług na najwyższym poziomie. Zaufaj naszym rozwiązaniom, aby wzmocnić swoją obecność w sieci i przyciągnąć nowych klientów.',
        subtitles: [
            {
                title: 'Technologie, których używamy:',
                contents: [
                    'React: Nowoczesny framework JavaScript, umożliwiający tworzenie dynamicznych i responsywnych interfejsów użytkownika. Dzięki React, nasze aplikacje są szybkie, wydajne i łatwe w rozbudowie.',
                    'WordPress: Najpopularniejszy system zarządzania treścią (CMS), idealny do tworzenia skalowalnych i łatwych w zarządzaniu stron internetowych oraz aplikacji, które można szybko dostosować do potrzeb biznesowych.',
                ]
            },
            {
                title: 'Przykłady zastosowania aplikacji webowych:',
                contents: [
                    'Sklepy internetowe: Kompleksowe platformy e-commerce, które pozwalają na zarządzanie sprzedażą online, obsługę zamówień, płatności i magazynu, a także integrację z systemami płatności i wysyłki.',
                    'Systemy zarządzania wewnętrznymi zasobami firmy (ERP): Aplikacje wspomagające zarządzanie procesami biznesowymi, takimi jak księgowość, zarządzanie zasobami ludzkimi, logistyka czy zarządzanie magazynem.',
                    'Portale informacyjne: Dynamiczne platformy do publikowania i zarządzania treściami, które mogą obsługiwać dużą ilość użytkowników oraz treści multimedialne.',
                    'Systemy rezerwacji: Aplikacje umożliwiające zarządzanie rezerwacjami w hotelach, restauracjach, wynajmem sprzętu lub usługami profesjonalnymi, z możliwością integracji z systemami płatności i kalendarzami.',
                    'Platformy edukacyjne: Narzędzia do zdalnego nauczania, które oferują dostęp do kursów online, testów, materiałów dydaktycznych oraz systemów zarządzania uczniami i nauczycielami.',
                ]
            },
            {
                title:'Korzyści dla klienta:',
                contents:[
                    'Dostępność: Aplikacje są dostępne z dowolnego urządzenia z dostępem do internetu, bez potrzeby instalowania dodatkowego oprogramowania.',
                    'Elastyczność: Aplikacje mogą być łatwo skalowane i rozbudowywane w miarę rozwoju Twojego biznesu.',
                    'Wysoka wydajność: Nowoczesne technologie, takie jak React, zapewniają szybkie i płynne działanie, co przekłada się na doskonałe doświadczenia użytkownika.',
                    'Łatwość zarządzania: Dzięki WordPressowi, możesz łatwo zarządzać treścią i funkcjonalnością aplikacji, nawet bez zaawansowanej wiedzy technicznej.',
                    'Bezpieczeństwo: Zabezpieczamy wszystkie nasze aplikacje zgodnie z najnowszymi standardami, chroniąc dane Twojej firmy i użytkowników.',
                    'Krótszy czas realizacji: Dzięki sprawdzonym technologiom i efektywnym procesom, szybko dostarczamy gotowe rozwiązania, dostosowane do Twoich potrzeb.',
                ]
            }
        ]
    },
    mobileapp: '',
    webapi: '',
    uiux: '',
    integrations: '',
    mvp: '',
}

export default servicesDetails;