import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, useMediaQuery } from "@mui/material";
import logoImg from "../../assets/img/logo.png";
import "./Header.css";
import headerData from "data/header";

import HeaderMobile from "./HeaderMobile";

const Header = () => {
    const navigate = useNavigate();
    const [isHovered, setIsHovered] = useState(false);
    const isNonMobileScreen = useMediaQuery("(min-width:1000px)");

    return (
        <Box
            width={"100%"}
            height={"auto"}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"center"}
            className="header-shadow"
        >
            {isNonMobileScreen ? (
                <Box
                    width={"80%"}
                    height={"10vh"}
                    display={"flex"}
                    flexDirection={"row"}
                    position={"sticky"}
                    justifyContent={"space-between"}
                >
                    <Box
                        height={"100%"}
                        width={"auto"}
                        display={"flex"}
                        justifyContent={"end"}
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                            navigate("/", { state: { scrollToStart: true } });
                        }}
                    >
                        <img src={logoImg} alt="logo" height={"100%"} width={"auto"} className="grey-img" />
                    </Box>
                    <Box
                        height={"100%"}
                        width={"35%"}
                        // border={"2px solid blue"}
                        display={"flex"}
                        flexDirection={"row"}
                        // gap={"1vw"}
                        justifyContent={"space-between"}
                    >
                        {headerData.map((item, index) => (
                            <Box
                                width={"auto"}
                                height={"100%"}
                                // border={"2px solid purple"}
                                display={"flex"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                onMouseEnter={() => setIsHovered(index)}
                                onMouseLeave={() => setIsHovered(null)}
                            >
                                <Typography
                                    variant="h3"
                                    onClick={() => {
                                        navigate(item.url, { state: item.state });
                                    }}
                                    className={
                                        index === isHovered ? "header-title header-title-border" : "header-title"
                                    }
                                >
                                    {item.title}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                    <Box
                        height={"100%"}
                        width={"20%"}
                        // border={"2px solid green"}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                    >
                        <Box
                            borderRadius={"16px"}
                            sx={{
                                background: "#1D3557",
                                boxShadow: " 0px 4px 8px rgba(0, 0, 0, 0.2)",
                                cursor: "pointer",
                                "&:hover": {
                                    background: `#0C6CE9`,
                                },
                            }}
                            width={"206px"}
                            height={"46px"}
                            textAlign={"center"}
                            display={"flex"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            onClick={() => {
                                navigate("/calculator", { state: { scrollToStart: true } });
                            }}
                        >
                            <Typography
                                variant="h5"
                                textAlign={"left"}
                                fontWeight={"bold"}
                                borderRadius={"16px"}
                                color={"white"}
                            >
                                Bezpłatna wycena
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            ) : (
                <HeaderMobile />
            )}
        </Box>
    );
};

export default Header;
