import consultingImg from "../assets/img/consulting.svg";
import designImg from "../assets/img/design.svg";
import developmentImg from "../assets/img/development.svg";
import endImg from "../assets/img/end.svg";

const realizationsStepsData = [
    {
        id: 1,
        title: "Konsultacja",
        text: "Podczas konsultacji dokładnie analizujemy Twoje potrzeby, cele biznesowe i wyzwania, przed którymi stoi Twoja firma. Dzięki temu możemy zaproponować rozwiązania, które najlepiej odpowiadają na Twoje oczekiwania i wymagania. ",
        mobileText:"Podczas konsultacji dokładnie analizujemy Twoje potrzeby, cele biznesowe i wyzwania, przed którymi stoi Twoja firma.",
        image: consultingImg,
    },
    {
        id: 2,
        title: "Projektowanie",
        text: "Nasz zespół projektantów UX/UI tworzy intuicyjne i atrakcyjne interfejsy, które nie tylko przyciągają wzrok, ale przede wszystkim zapewniają doskonałe doświadczenie użytkownika.",
        mobileText:"Nasz zespół projektantów UX/UI tworzy intuicyjne i atrakcyjne interfejsy, które nie tylko przyciągają wzrok...",  
        image: designImg,
    },
    {
        id: 3,
        title: "Rozwój oprogramowania",
        text: "Po zatwierdzeniu projektu graficznego przystępujemy do wdrożenia i rozwoju oprogramowania. Nasz zespół, korzystając z nowoczesnych technologii i sprawdzonych metodologii, tworzy funkcjonalne oprogramowanie, które dokładnie odzwierciedla założenia projektowe.",
        mobileText:"Po zatwierdzeniu projektu graficznego przystępujemy do wdrożenia i rozwoju oprogramowania.",
        image: developmentImg,
    },
    {
        id: 4,
        title: "Wdrożenie",
        text: `Naszym celem jest zapewnienie płynnego i bezproblemowego przejścia z fazy testowej do rzeczywistego użycia. Dokładamy wszelkich starań, aby proces wdrożenia przebiegał sprawnie i z minimalnym wpływem na bieżące operacje Twojej firmy.`,
        mobileText:"Naszym celem jest zapewnienie płynnego i bezproblemowego przejścia z fazy testowej do rzeczywistego użycia.",
        image: endImg,
    },
];
export default realizationsStepsData;
