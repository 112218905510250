import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, useTheme } from "@mui/material";
import Button from "components/Interface/Button";
const ServicesCard = ({ image, title, text, url }) => {
    const theme = useTheme();
    const palette = theme.palette;
    const navigate = useNavigate();

    return (
        <Box
            width={"100%"}
            minHeight={"40vh"}
            height={"auto"}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            flexDirection={"column"}
            // gap={"30px"}
            sx={{
                border: `2px solid white`,
                boxShadow: " 0px 4px 8px rgba(0, 0, 0, 0.2)",
                borderRadius: "16px",
                "&:hover": {
                    border: `2px solid ${palette.secondary.main}`,
                },
            }}
        >
            <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
                // border={"2px solid red"}
                paddingTop={"20px"}
                paddingLeft={"20px"}
                paddingRight={"20px"}
                width={"100%"}
                gap={"30px"}
            >
                <Box
                    display={"flex"}
                    width={"85%"}
                    alignItems={"center"}
                    // marginBottom={"24px"}
                    maxHeight={"30%"}
                >
                    <img src={image} alt="{title}" height={"100%"} />
                    <Typography variant="h2" marginLeft={"30px"} fontWeight={"bold"} fontSize={"1.8em"}>
                        {title}
                    </Typography>
                </Box>
                <Box width={"100%"} lineHeight={"22px"} minHeight={"30%"} height={"auto"}>
                    <Typography variant="h5">{text}</Typography>
                </Box>
            </Box>
            {/* <Box
                width={"85%"}
                height={"10%"}
                display={"flex"}
                justifyContent={"end"}
                justifySelf={"end"}
                marginBottom={"20px"}
            >
                <Button text={"Więcej"} url={url} />
            </Box> */}
        </Box>
    );
};

export default ServicesCard;
