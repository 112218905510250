const headerData = [
    {
        title: "O nas",
        url: "/",
        state: { scrollToStart: true },
    },
    {
        title: "Portfolio",
        url: "/portfolio",
        state: { scrollToStart: true },
    },
    {
        title: "Kontakt",
        url: "/",
        state: { scrollToForm: true },
    },
];

export default headerData;
