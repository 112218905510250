import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
const ServicesMobile = ({ item }) => {
    const theme = useTheme();
    const palette = theme.palette;
    const navigate = useNavigate();

    const [showText, setShowText] = useState(false);

    return (
        <Box
            width={"auto"}
            minHeight={"15vh"}
            height={"auto"}
            display={"flex"}
            flexDirection={"column"}
            sx={{
                border: `2px solid white`,
                boxShadow: " 0px 4px 8px rgba(0, 0, 0, 0.2)",
                borderRadius: "16px",
                "&:hover": {
                    border: `3px solid ${palette.secondary.main}`,
                },
                cursor: "pointer",
            }}
            onClick={(e) => {
                setShowText((item) => !item);
            }}
        >
            <Box
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"center"}
                height={"15vh"}
                // justifyItems={"center"}
            >
                <Box display={"flex"} width={"85%"} alignItems={"center"} maxHeight={"30%"}>
                    <img src={item.image} alt="{title}" height={"80vh"} />
                    <Typography variant="h3" marginLeft={"30px"} fontWeight={"bold"}>
                        {item.title}
                    </Typography>
                </Box>
            </Box>
            {showText ? (
                <Box width={"auto"} height={"auto"} paddingLeft={"8vw"} paddingRight={"8vw"} marginBottom={"15px"}>
                    <Box lineHeight={"22px"} marginBottom={"20px"}>
                        {item.text}
                    </Box>
                    <Box width={"auto"} display={"flex"} justifyContent={"end"}>
                        {/* <Box
                            borderRadius={"16px"}
                            sx={{
                                background: "#1D3557",
                                boxShadow: " 0px 4px 8px rgba(0, 0, 0, 0.2)",
                                cursor: "pointer",
                                "&:hover": {
                                    background: `#0C6CE9`,
                                },
                            }}
                            width={"128px"}
                            height={"38px"}
                            textAlign={"center"}
                            display={"flex"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            onClick={() => {
                                navigate(item.url);
                            }}
                        >
                            <Typography
                                variant="p"
                                textAlign={"left"}
                                fontWeight={"bold"}
                                borderRadius={"16px"}
                                color={"white"}
                            >
                                Więcej
                            </Typography>
                        </Box> */}
                    </Box>
                </Box>
            ) : (
                <></>
            )}
        </Box>
    );
};

export default ServicesMobile;
