import React from "react";
import { useState, useEffect, useRef } from "react";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import androidLogo from "../../assets/logos/adroid.png";
import awslogo from "../../assets/logos/aws.png";
import flutterLogo from "../../assets/logos/flutter.png";
import firebaseLogo from "../../assets/logos/firebase.png";
import reduxlogo from "../../assets/logos/reduxLogo.png";
import reactLogo from "../../assets/logos/reactLogo.png";
const LogoWaterfall = () => {
    // const logoArr = [androidLogo, awslogo, flutterLogo, firebaseLogo, reduxlogo, reactLogo];
    const logoArr = [
        {
            logo: androidLogo,
            x: 30,
            delay: 0,
            name: `Android-logo`,
            width: "200px",
        },
        {
            logo: reactLogo,
            x: 40,
            delay: 2,
            name: `react-logo`,
            width: "150px",
        },
        {
            logo: flutterLogo,
            x: 10,
            delay: 3,
            name: `flutter-logo`,
            width: "130px",
        },
        {
            logo: awslogo,
            x: 60,
            delay: 10,
            name: `aws-logo`,
            width: "200px",
        },
        {
            logo: firebaseLogo,
            x: 50,
            delay: 6,
            name: `firebase-logo`,
            width: "200px",
        },
        {
            logo: reduxlogo,
            x: 50,
            delay: 11,
            name: `redux-logo`,
            width: "150px",
        },
    ];

    return (
        <Box
            width={"100%"}
            height={"100%"}
            className="floating-images-container"
            sx={{ background: "linear-gradient(to bottom, #FFFFFF 100%, #FFFFFF 0%, #FFFFFF 100% )" }}
        >
            {logoArr.map((logoItem, index) => {
                return (
                    <img
                        key={logoItem.name}
                        src={logoItem.logo}
                        className="floating-image"
                        // onTransitionEnd={handleAnimationEnd}
                        style={{
                            left: `${logoItem.x}%`, // Losowa pozycja dla każdego obrazka
                            animationDelay: `${logoItem.delay}s`, // Losowe opóźnienie animacji dla każdego obrazka
                            width: logoItem.width,
                        }}
                        alt={`Floating ${logoItem.name}`}
                    />
                );
            })}
            {/* {handleFloatingImg()} */}
        </Box>
    );
};

export default LogoWaterfall;
