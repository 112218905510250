import React from "react";
import { useState } from "react";
import { Box, Typography } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./FormContainer.css";

const validateData = (data) => {
    if (data.name === "") {
        toast.error("Brak imienia i nazwiska, prosimy zupełnić pole", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "dark",
        });
        return false;
    } else if (data.email === "") {
        toast.error("Brak emaila, prosimy zupełnić pole", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light",
        });
        return false;
    }
    return true;
};

const FormContainer = () => {
    const [inputData, setInputData] = useState({ name: "", email: "", company: "", description: "" });

    const handleSubmit = (event) => {
        console.log(inputData);
        event.preventDefault();
        console.log("validateData(inputData)", validateData(inputData));
        if (validateData(inputData)) {
            setInputData({
                name: "",
                email: "",
                company: "",
                description: "",
            });
            toast.success("Dziękujemy za wiadomość!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
    };

    return (
        <Box width={"100%"} height={"100%"}>
            <form onSubmit={handleSubmit} display={"flex"} flexDirection={"column"} id="formularz">
                <Box display={"flex"} flexDirection={"column"} marginBottom={"20px"}>
                    <label>
                        <Typography variant="p">Imię i nazwisko</Typography>
                    </label>
                    <input
                        style={{ height: "40px" }}
                        width={"100%"}
                        className="input-container"
                        type="text"
                        name="name"
                        value={inputData.name}
                        onChange={(e) => {
                            setInputData((item) => ({ ...item, name: e.target.value }));
                        }}
                    />
                </Box>
                <Box display={"flex"} flexDirection={"column"} marginBottom={"20px"}>
                    <label>
                        <Typography variant="p">Email</Typography>
                    </label>
                    <input
                        width={"100%"}
                        style={{ height: "40px" }}
                        className="input-container"
                        type="email"
                        name="email"
                        value={inputData.email}
                        onChange={(e) => {
                            setInputData((item) => ({ ...item, email: e.target.email }));
                        }}
                    />
                </Box>
                <Box display={"flex"} flexDirection={"column"} marginBottom={"20px"}>
                    <label>
                        <Typography variant="p">Nazwa firmy (opcjonalne)</Typography>
                    </label>
                    <input
                        width={"100%"}
                        style={{ height: "40px" }}
                        className="input-container"
                        type="text"
                        name="company"
                        value={inputData.company}
                        onChange={(e) => {
                            setInputData((item) => ({ ...item, company: e.target.value }));
                        }}
                    />
                </Box>
                <Box display={"flex"} flexDirection={"column"} marginBottom={"20px"}>
                    <label>
                        <Typography variant="p">Opis projektu (opcjonalne)</Typography>
                    </label>
                    <textarea
                        width={"100%"}
                        style={{ height: "170px", paddingTop: "20px" }}
                        className="input-container"
                        type="text"
                        name="description"
                        value={inputData.description}
                        onChange={(e) => {
                            setInputData((item) => ({ ...item, description: e.target.value }));
                        }}
                    />
                </Box>
                <Box
                    width={"100%"}
                    height={"10vh"}
                    // border={"2px solid red"}
                    display={"flex"}
                    flexDirection={"row"}
                    alignContent={"end"}
                    justifyContent={"end"}
                >
                    <button className="from-btn" type="submit">
                        <Typography
                            variant="h5"
                            textAlign={"left"}
                            fontWeight={"bold"}
                            borderRadius={"16px"}
                            color={"white"}
                        >
                            Wyślij
                        </Typography>
                    </button>
                </Box>
                <ToastContainer />
            </form>
        </Box>
    );
};

export default FormContainer;
