import React from "react";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import FormContainer from "components/ContactComponent/FormContainer/FormContainer";

import ShadowBox from "components/Interface/ShadowBox";

const ContactComponentMobile = () => {
    const theme = useTheme();
    const palette = theme.palette;
    const isNonMinMobileScreen = useMediaQuery("(min-width:500px)");

    return (
        <Box
            width={isNonMinMobileScreen ? "90%" : "100%"}
            height={"auto"}
            marginLeft={"auto"}
            marginRight={"auto"}
            marginBottom={"10vh"}
            sx={{ boxShadow: " 0px 4px 8px rgba(0, 0, 0, 0.2)", borderRadius: "16px" }}
        >
            <Box
                width={"100%"}
                height={"auto"}
                minHeight={"10vh"}
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
                marginBottom={"3vh"}
                marginTop={"10vh"}
                padding={isNonMinMobileScreen ? "30px" : "10px"}
            >
                <Typography variant="h1">
                    <span style={{ color: palette.secondary.main }}>Kontakt</span>
                </Typography>
                <Typography variant="h5" fontWeight={"bold"} marginTop={"20px"}>
                    Jeżeli masz jakiekolwiek pytania, potrzebujesz wyceny, lub chcesz porozmawiać o swoim projekcie,
                    jesteśmy do Twojej dyspozycji.
                </Typography>
            </Box>
            <Box padding={"20px"}>
                <FormContainer />
            </Box>
        </Box>
    );
};

export default ContactComponentMobile;
