import React from "react";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import realizationsStepsData from "data/realizationSteps";

import ShadowBox from "components/Interface/ShadowBox";
const StepsComponentMobile = () => {
    const theme = useTheme();
    const palette = theme.palette;
    const isNonMinMobileScreen = useMediaQuery("(min-width:500px)");

    return (
        <ShadowBox
            headerContent={
                <Typography variant="h1" textAlign={"center"}>
                    Od <span style={{ color: palette.secondary.main }}>pomysłu</span> do{" "}
                    <span style={{ color: palette.secondary.main }}>realizacj</span>
                </Typography>
            }
        >
            <Box
                width={isNonMinMobileScreen ? "90%" : "100%"}
                height={"auto"}
                alignSelf={"center"}
                borderRadius={"16px"}
                display={"flex"}
                flexDirection={"row"}
                gap="5vw"
                className="steps-row "
                padding={"30px"}
                sx={{ overflowY: "hidden" }}
            >
                {realizationsStepsData.map((item, index) => (
                    <Box
                        borderRadius={"16px"}
                        height={"auto"}
                        border={`1px solid ${palette.secondary.main}`}
                        sx={{
                            boxShadow: " 0px 4px 8px rgba(0, 0, 0, 0.2)",
                            borderRadius: "16px",
                        }}
                        padding={"32px"}
                        display={"flex"}
                        flex={isNonMinMobileScreen ? "0 0 21rem" : "0 0 18rem"}
                        flexDirection={"column"}
                    >
                        <Box
                            width={"90%"}
                            height={"auto"}
                            marginBottom={"20px"}
                            display={"flex"}
                            flexDirection={"row"}
                            // border={"2px solid red"}
                        >
                            <Box height={"100%"} width={"auto"}>
                                <Box
                                    borderRadius={"50%"}
                                    height={"40px"}
                                    width={"40px"}
                                    sx={{ background: palette.primary.main }}
                                    display={"flex"}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                >
                                    <Typography variant="h3" color={"white"} fontWeight={"bold"}>
                                        {item.id}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box
                                height={"100%"}
                                width={"70%"}
                                display={"flex"}
                                justifyItems={"center"}
                                alignItems={"center"}
                                // border="2px solid purple"
                                marginLeft={"10px"}
                            >
                                <Typography variant="h3" fontWeight={"bold"}>
                                    {item.title}
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            width={"90%"}
                            height={"90%"}
                            display={"flex"}
                            flexDirection={"column"}
                            justifyContent={"center"}
                            // border={"3px solid green"}
                        >
                            <Box width={"100%"} height={"auto"}>
                                <Typography variant="h5">{item.mobileText}</Typography>
                            </Box>
                        </Box>
                    </Box>
                ))}
            </Box>
        </ShadowBox>
    );
};

export default StepsComponentMobile;
