export const colorsPallet = {
    blue: "#1D3557",
    grey: "#A8A8A8",
    green: "#8CB369",
    white: "#FFFFFF",
};

export const themeSettings = (mode) => {
    return {
        palette: {
            mode: mode,
            primary: {
                main: colorsPallet.blue,
                light: colorsPallet.white,
            },
            secondary: {
                main: colorsPallet.green,
                light: colorsPallet.grey,
            },
        },
        typography: {
            fontFamily: ["Sen", "sans-serif"].join(","),
            fontSize: 12,
            h1: {
                fontFamily: ["Sen", "sans-serif"].join(","),
                fontSize: 32,
                fontWeight: 800,
            },
            h2: {
                fontFamily: ["Sen", "sans-serif"].join(","),
                fontSize: 28,
            },
            h3: {
                fontFamily: ["Sen", "sans-serif"].join(","),
                fontSize: 24,
                fontWeight: 500,
            },
            h4: {
                fontFamily: ["Sen", "sans-serif"].join(","),
                fontSize: 20,
            },
            h5: {
                fontFamily: ["Sen", "sans-serif"].join(","),
                fontSize: 16,
            },
            h6: {
                fontFamily: ["Sen", "sans-serif"].join(","),
                fontSize: 14,
            },
            p: {
                fontFamily: ["Sen", "sans-serif"].join(","),
                fontSize: 12,
                fontWeight: 700,
            },
        },
    };
};
