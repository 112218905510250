import React from "react";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";

import ShadowBox from "components/Interface/ShadowBox";

import ServicesCard from "./ServicesCard";
import servicesData from "../../../data/services";
const ServicesComponent = () => {
    const isThreeColumns = useMediaQuery("(min-width:1300px)");
    const theme = useTheme();
    const palette = theme.palette;

    return (
        <Box
            width={"100%"}
            height={"auto"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            marginBottom={"20vh"}
        >
            <ShadowBox
                headerContent={
                    <Typography variant="h1">
                        Nasze <span style={{ color: palette.secondary.main }}>usługi</span>
                    </Typography>
                }
            >
                <Box
                    display={"grid"}
                    gridTemplateColumns={isThreeColumns ? "repeat(3, minmax(0, 1fr))" : "repeat(2, minmax(0, 1fr))"}
                    gridTemplateRows={"auto auto"}
                    rowGap={"5vh"}
                    columnGap={"2vw"}
                    padding={"50px"}
                    paddingTop={"0px"}
                >
                    {servicesData.map((item, index) => {
                        return (
                            <ServicesCard
                                key={index}
                                image={item.image}
                                title={item.title}
                                text={item.text}
                                url={item.url}
                            />
                        );
                    })}
                </Box>
            </ShadowBox>
        </Box>
    );
};

export default ServicesComponent;
